import api from "@/endpoints/api"
import axios from "axios"

export async function s3Download(fileKey) {
    try {
        const response = await api.get("/api/media/signed-download-url/", { params: { file_key: fileKey } });
        const downloadURL = response.data.downloadURL;

        return downloadURL;
    } catch (err) {
        console.error("Error fetching download URL: ", err);
    }
}