import axios from "axios"
import { getCsrfToken } from "@/utils/csrfTokens"

const api = axios.create({
  baseURL: "https://dev.strollopia.com/",
  // baseURL: "/",
  headers: {
    "Content-Type": "application/json"
  },
})

const requestHandler = request => {
  request.headers["X-CSRFToken"] = getCsrfToken()
  return request;
}

const errorHandler = error => {
  return Promise.reject(error);
}

api.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
)

export default api