import api from "@/endpoints/api"
import { postData } from '../persistence';

export function addStroll(data) {
    console.log('Submitting data:', data);
    
    // You need to specify a unique key here. This key is used to manage session or transaction identification.
    const sessionKey = 'uniqueSessionKey';  // Replace 'uniqueSessionKey' with actual logic to generate or retrieve a key
    
    // Use postData to handle data submission
    return postData(data, sessionKey);
}

export function getPublicMap(id) {
    return api.get(`/api/p-map/${id}/`)
}

export function getCategories() {
    return api.get('/api/categories/')
}