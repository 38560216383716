import api from "@/endpoints/api"
import axios from "axios"


export async function s3Upload(file) {
    try {
        const { data } = await api.get("/api/media/signed-url/", {params: {filename: file.name}}) ///// 
        const options = {
            headers: {
                "Content-Type": file.type,
            }
        }
        const publicUploadID = data.publicUploadID

        await axios.put(data.uploadURL, file, options)
        try {
            await api.put(`/api/media/public-upload/${publicUploadID}/process/`, {publicUploadID: publicUploadID})
        } catch (err) {
            console.log("Error processing file: ", err)
        }

        return publicUploadID;
    } catch (err) {
        console.log("Error uploading file: ", err)
    }
}