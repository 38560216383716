import api from "@/endpoints/api"
import { openDB } from 'idb';

const dbPromise = openDB('myDatabase', 1, {
    upgrade(db) {
      // Create an object store 'formData' with 'id' as the primary key and auto-increment enabled
      db.createObjectStore('formData', { keyPath: 'id', autoIncrement: true });
    },
  });

  // Function to check if the navigator is online
const isOnline = () => {
    return navigator.onLine;
  }

  const postData = async (data, key) => {
    if (isOnline()) {
      // Online scenario: Post data to the API
      try {
        const response = await api.post('/endpoint', { ...data, key });
        console.log('Data sent to API:', response.data);
      } catch (error) {
        console.error('Error posting data:', error);
      }
    } else {
      // Offline scenario: Save data locally in IndexedDB
      try {
        const db = await dbPromise;
        const tx = db.transaction('formData', 'readwrite');
        await tx.objectStore('formData').add(data);
        await tx.complete;
        console.log('Data saved locally');
      } catch (error) {
        console.error('Error saving data locally:', error);
      }
    }
  }

  export { postData };
